<template>
  <div class="uploadImage">
    <el-upload v-loading="loading" action="https://up-z0.qiniup.com" multiple :show-file-list="false" :before-upload="before_img" :on-success="img_sc"
      :data="postData">
      <slot name="uploadContent"></slot>
    </el-upload>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    limit: {
      type: Number,
      default: 9
    },
    stats: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      postData: {
        token: '',
        key: ''
      },
      image_url: "",
      loading: false
    }
  },
  mounted () {
    this.initQiNiuToken();
  },
  methods: {
    ...mapMutations(['SET_SHOWLOADING']),
    initQiNiuToken () {
      this.$api.getQiniuToken().then(res => {
        this.postData.token = res.data.token;
      });
    },
    before_img (res) {
      console.log('上传之前,ggggg', res);
      this.loading = true;
      this.postData.key = Math.ceil(Math.random() * 999999) + res.name;
    },
    //多张图片上传
    img_sc (res) {
      this.image_url = '/' + res.key;
      this.$emit('success', { img: this.image_url, status: this.stats });
      this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.uploadImage {
}
</style>